<template>
	<div>
		<inventory-changes-table
			:has-data="hasData"
			:loading="loading"
			:changes="changes"
			@currentDetail="currentDetail = $event" />
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<inventory-changes-detail
			:id="currentDetail"
			is-pending-transfer
			@completed="getInTransit" />
	</div>
</template>

<script>
import InventoryChangesTable from '@/views/Inventory/Logs/InventoryChanges/InventoryChangesTable.vue';
import InventoryChangesDetail from '@/views/Inventory/Logs/InventoryChanges/InventoryChangesDetail.vue';
import Inventory from '@/util/Inventory';

export default {
	name: 'InTransitInventory',
	components: {
		InventoryChangesTable,
		InventoryChangesDetail,
	},
	data() {
		return {
			inventory: new Inventory(),
			currentDetail: '',
		};
	},
	computed: {
		changes() {
			try {
				return this.inventory.data2.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loading() {
			try {
				return !!this.inventory.data2.loading;
			} catch (error) {
				return false;
			}
		},
		hasData() {
			return this.changes.length > 0;
		},
	},
	mounted() {
		this.getInTransit();
	},
	methods: {
		getInTransit() {
			this.inventory.inTransit();
		},
	},
};
</script>
